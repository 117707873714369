import React from 'react';
import { Helmet } from 'react-helmet';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  PostFeed,
  ResponsiveHeaderBackground,
  SiteArchiveHeader,
  SiteHeader,
  SiteHeaderBackground,
  SiteHeaderContent,
  SiteMain,
  SiteNavMain,
  PageTitle,
  PageDescription,
  MoreContact
} from '../styles/shared';
import { PageContext } from '../templates/note';

import { graphql } from 'gatsby';
import config from '../config/website-config';
import { PostCard } from '../components/PostCard';

interface NoteTemplateProps {
  data:{
    allMarkdownRemark: {
      totalCount: number;
      edges: Array<{
        node: PageContext;
      }>;
    };
  }
}

const Note: React.FC<NoteTemplateProps> = props => {
  let noteLength = props.data.allMarkdownRemark.edges.length;
  let largeStatus = true;

  if(noteLength>1){
    largeStatus = false
  }

  return(
    <IndexLayout>
      <Helmet>
        <html lang={config.lang} />
        <title>
          Note - {config.title}
        </title>
      </Helmet>
      <Wrapper>
        <header
          className="site-archive-header"
          css={[SiteHeader, SiteArchiveHeader]}
        >
          <div css={[outer, SiteNavMain]}>
            <div css={inner}>
              <SiteNav isHome={false} />
            </div>
          </div>
          <ResponsiveHeaderBackground
            css={[outer, SiteHeaderBackground]}
            // backgroundImage={tagData?.node?.image?.childImageSharp?.fluid?.src}
            className="site-header-background"
          >
            <SiteHeaderContent css={inner} className="site-header-content">
              <h1 css={PageTitle}>Note</h1>
              <h3 css={PageDescription}>
                บันทึก (น่าจะ) สั้น ๆ ที่ได้เรียนรู้หรือไปเจอมา
              </h3>
            </SiteHeaderContent>
          </ResponsiveHeaderBackground>
        </header>
        <main id="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <div css={[PostFeed]}>
              {noteLength > 0 ? 
                  props.data.allMarkdownRemark.edges.map((post) => {
                    return (
                      <PostCard key={post.node.fields.slug} post={post.node} large={largeStatus} />
                    );
                  })
              : <MoreContact> ยังไม่มีเนื้อหาเลย ช่วยรอก่อนนะ... </MoreContact>}
            </div>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default Note;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {frontmatter: {type: {eq: "note"}, draft: {eq: false}}}
      sort: {fields: frontmatter___date, order: DESC}
      ) {
      edges {
        node {
          frontmatter {
            title
            type
            date
            tags
            draft
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fluid(quality: 100, srcSetBreakpoints: [40, 80, 120]) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          excerpt
          fields {
            readingTime {
              text
            }
            layout
            slug
          }
        }
      }
    }
  }
`;